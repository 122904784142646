#tradeform h1 {
  font: normal normal 800 70px/80px Poppins;
  text-align: left;
}
#tradeform h2 {
  font: normal normal 300 25px/42px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  text-align: left;
  /* opacity: 0.5; */
  margin-bottom: 0;
}
.errorMessage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
#tradeform .errorMsg {
  font: normal bold normal 15px/27px Open Sans !important;
  color: red !important;
  text-align: center !important;
  width: 100% !important;
}
#tradeform .errorMsg span {
  color: #1259ff !important;
}
#tradeform .telephone select {
  width: 20% !important;
  background: none !important;
}
#tradeform .telephone {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: space-between; */
}
#tradeform .telephone input {
  -webkit-appearance: none;
  padding-left: 15px;
}
#tradeform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
#tradeform p {
  font: normal normal normal 20px/27px Poppins;
  color: #5a5a5a;
  text-align: left;
}
#tradeform p.validationError {
  color: red;
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}
.formflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.formflex button,
.formflex input,
.formflex select {
  width: 100%;
  height: 50px;
  border: 0;
  font-size: 20px;
  border-bottom: 1px solid #111;
  margin-bottom: 10px;
  outline: none;
  -webkit-transition: border-width 0.3s linear;
  -o-transition: border-width 0.3s linear;
  transition: border-width 0.3s linear;
}
.formflex select {
  background: none !important;
}
.formflex label {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0;
}
.formflex .nextbtn {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 24px;
}
.formflex label input {
  width: 48%;
}
.formflex button {
  max-width: 180px;
  min-width: 180px;
  background: transparent;
  min-height: 64px;
  font: normal normal 600 20px Poppins;
  text-align: left;
  color: rgba(17, 17, 17, 0.521);
  border: none;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.formflex button:hover {
  color: #1259ff;
}
.formflex input.submitInput {
  max-width: 180px;
  min-width: 180px;
  background: #1259ff;
  min-height: 64px;
  font: normal normal 600 24px/34px Poppins;
  color: #ffffff;
  border: 2px solid #1259ff;
  border-radius: 11px;
  text-align: center;
  cursor: pointer;
}
.formflex input.submitInput.left {
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-left: auto;
}
.formflex button {
  margin-right: 10px;
}
.formflex button:hover,
.formflex input:hover,
.formflex select:hover {
  border-bottom: 2px solid #1259ff;
}
.formflex input.submitInput:hover {
  background: transparent;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #111111;
}
.backBtn:hover {
  border: 0 !important;
}
@media (max-width: 1200px) {
  #tradeform h1 {
    font: normal normal 800 63px/69px Poppins;
  }
  #tradeform h2 {
    font: normal normal 300 25px/42px Open Sans;
  }
}
@media (max-width: 991px) {
  .formflex .nextbtn {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #tradeform h1 {
    font: normal normal 800 70px/80px Poppins;
  }
}
@media (max-width: 575px) {
  #tradeform h1 {
    font: normal normal 800 41px/45px Poppins;
  }
  #tradeform h2 {
    font: normal normal 300 21px/25px Verveine;
  }
  .formflex .backBtn,
  .formflex input.submitInput {
    max-width: 120px;
    min-width: 120px;
  }
}
