#referafriend {
  padding-top: 100px;
  padding-bottom: 100px;
}
#referafriend .hflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#referafriend h3 {
  text-align: left;
  letter-spacing: -0.8px;
  font: normal normal bold 35px/55px Poppins;
  letter-spacing: -0.8px;
  max-width: 520px;
  color: #000000;
}
#referafriend .hflexRight {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
#referafriend .content {
  text-align: left;
  max-width: 520px;
}
#referafriend .download {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  padding-top: 30px;
}
.referPhone {
  position: relative;
}

.refercircle {
  position: absolute;
  width: 100%;
  max-width: 200px;
  bottom: 0;
  right: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: initial;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@media (max-width: 991px) {
  #referafriend {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #referafriend h3 {
    padding-top: 40px;
    text-align: center;
  }
  #referafriend .hflexRight p {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .refercircle {
    max-width: 130px;
    right: 0px;
  }
  .referPhone {
    overflow: hidden;
  }
  #referafriend {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #referafriend h3 {
    font: normal normal bold 29px/36px Poppins;
    padding-top: 50px;
  }
  #referafriend .download {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #referafriend .referandroid {
    margin-top: 20px;
  }
}
