#navBar {
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  -webkit-box-shadow: 0 2px 20px #bebebe;
  box-shadow: 0 2px 20px #bebebe;
  border-bottom: 1px solid #e2dfdf;
  background-color: #ffffff;
}

#navBar .navBarSp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#navBar .navBarSp .right-side {
  display: flex;
  justify-content: center;
  align-items: center;
}
#navBar .navBarSp .left-side {
  display: flex;
  justify-content: center;
  align-items: center;
}
#navBar .right-side .login-btn {
  color: #1779ff;
  background: rgba(4, 110, 255, 0.12);
  padding: 5px 8px;
  border-radius: 5px;
  min-width: 105px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: thin solid rgba(4, 110, 255, 0.8);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}

#navBar .right-side .login-btn:hover {
  color: #fff;
  background: rgba(4, 110, 255, 0.24);
  border: thin solid rgb(4, 110, 255);
  text-decoration: none;
}
#navBar .right-side .register-btn {
  border: 2px solid #ff7a04;
  background-color: #ff7a04;
  color: #ffffff !important;
  border-radius: 5px;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 120px;
  font-size: 14px;
  height: unset;
  margin: 0 5px;

  padding: 4px 8px;
  min-width: 115px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  text-decoration: none;
}

#navBar .right-side .register-btn:hover {
  background-color: transparent;
  color: #ff7a04 !important;
}
#navBar .right-side .ctaS {
  max-width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}

@media (max-width: 575px) {
  #navBar .right-side .login-btn {
    padding: 4px 8px;
  }
  #navBar .right-side .register-btn {
    border: thin solid #ff7a04;
  }
  #navBar .navBarSp {
    flex-direction: column;
    justify-content: center;
  }
  #navBar .right-side {
    padding-top: 10px;
  }
}
