#steps {
  padding-top: 100px;
  padding-bottom: 100px;
}
#steps .hflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
#steps h1 {
  font: normal normal bold 25px/60px Poppins;
  color: #000000;
}
.registerC {
  color: #1259ff;
  text-decoration: underline;
  transition: 0.3s all ease;
}
.nflex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#steps .nflex h4 {
  padding-top: 0 !important;
  padding-bottom: 220px;
}
#steps .stepF {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 400px;
  height: 100%;
}
.registerC:hover {
  color: #0e3ca7;
  cursor: pointer;
}
#steps h6 {
  font: normal normal bold 28px/60px Poppins;
  color: #000000;
}
#steps .hflex .stepsp1,
#steps .hflex .stepsp2 {
  font: normal normal normal 17px/20px Poppins;
}
#steps .hflex .stepsp2 {
  color: #989898;
}
#steps .stepscontent {
  max-width: 405px;
  min-height: 340px;
}
#steps .carousel .control-next.control-arrow:before {
  content: ">";
  font-size: 80px;
  border: 0;
  font-family: monospace;
  position: absolute;
}
.carousel.carousel-slider .control-arrow {
  margin-top: unset;
  margin: auto 14px;

  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 60px;
  margin-top: 0;
  padding: 5px;
  right: 20px;
}
#steps .carousel .control-prev.control-arrow:before {
  content: "<";
  font-size: 80px;
  border: 0;
  font-family: monospace;
  position: absolute;
}
#steps h4 {
  color: #1259ff;
  font: normal normal 600 100px/60px Poppins;
  padding-top: 103px;
  text-align: right;
}

.carousel.carousel-slider .control-arrow:hover,
button.control-arrow.control-prev:hover {
  background: none;
}
#steps button.control-arrow.control-next,
#steps button.control-arrow.control-prev {
  color: #1259ff;
}
@media (min-width: 1200px) {
  #steps h4 {
    padding-top: 196px;
  }
}
@media (max-width: 991px) {
  #steps .hflex {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  #steps {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #steps h4 {
    text-align: center;
    padding-top: 20px;
  }
  #steps .nflex {
    justify-content: center;
  }
  #steps .nflex h4 {
    padding-top: 30px !important;
    padding-bottom: 0 !important;
  }
  #steps h1 {
    font: normal normal bold 23px/60px Poppins;
    text-align: center;
  }
}
