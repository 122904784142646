#deposits {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f8f8f8;
}
#deposits h1 {
  text-align: center;
  font: normal normal bold 35px/60px Poppins;
  letter-spacing: -0.65px;
  color: #000000;
}
#deposits .hflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#deposits img {
  max-width: 110px;
  height: auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}
@media (max-width: 991px) {
  #deposits {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
@media (max-width: 575px) {
  #deposits h1 {
    font: normal normal bold 29px/10px Poppins;
  }
}
