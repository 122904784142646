#hero {
  padding-top: 160px;
  padding-bottom: 100px;
}
#hero .heroimg {
  max-width: 70%;
  position: relative;
  z-index: 1;
  -webkit-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  transform: rotate(342deg);
}
#hero .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
#hero .dollarimg {
  position: absolute;
  bottom: -89px;
  left: 26px;
  width: 270px;
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
}
#hero .imgbox {
  position: relative;
}
#hero .appleimg {
  position: absolute;
  top: -120px;
  right: 53px;
  width: 270px;
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
}

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

#hero .hflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  #hero .heroimg {
    padding-top: 55px;
  }

  #hero .imgbox {
    max-width: 500px;
    padding-top: 100px;
  }
  #hero .appleimg {
    top: 0px;
  }
}
@media (max-width: 575px) {
  #hero {
    padding-top: 120px;
    padding-bottom: 40px;
  }

  #hero .imgbox {
    max-width: 300px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  #hero .appleimg {
    width: 150px;
    right: 34px;
    top: 17px;
  }
  #hero .dollarimg {
    width: 150px;
    bottom: -9px;
    left: 31px;
  }
}
