#pages {
  background-color: rgb(82, 86, 89);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
#pages .pageA4 .TermsTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
#pages .pageA4 {
  background-color: white;
  max-width: 800px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  min-height: 1200px;
  padding: 70px;
}
#pages .pageA4 h5 {
  padding-top: 20px;
}
#pages .pageA4 h6 {
  padding-top: 20px;
}
#pages .pageA4 td {
  padding: 10px;
  border: 1px solid black;
}

#pages .pageA4 ol {
  margin-left: 10px;
  margin-right: 10px;
}
#pages .pageA4 ol li {
  padding-left: 10px;
  margin-bottom: 0;
  font-weight: 500;
}
#pages .pageA4 ol li p {
  font-weight: 500;
  margin-bottom: 0;
}
#pages .pageA4 .CFDssize {
  font-size: 12px;
}
#pages .pageA4 .bronzeRow {
  background-color: orange;
}
#pages .pageA4 .silverRow {
  background-color: silver;
}
#pages .pageA4 .goldRow {
  background-color: gold;
}
#pages .pageA4 .goldRow,
#pages .pageA4 .silverRow,
#pages .pageA4 .bronzeRow {
  font-weight: 500;
}

#pages .pageA4 .platRow {
  background-color: gray;
  color: #ffffff;
  font-weight: 500;
}

@media (max-width: 991px) {
  #pages .pageA4 {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  ol {
    margin: 0;
    padding: 10px;
  }
}

@media (max-width: 575px) {
  #pages .pageA4 {
    margin: 20px 0;
    padding: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  ol {
    margin: 0;
    padding: 10px;
  }
}
