#footer .footer-logo img {
  max-width: 180px;
  width: 100%;
  -webkit-filter: brightness(0);
  filter: brightness(0);
  opacity: 0.7;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#footer .footer-logo img:hover {
  -webkit-filter: brightness(1);
  filter: brightness(1);
  opacity: 1;
}
.disclaimer-regulation {
  padding-top: 12px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #dee2e6;
}
p.footnote,
p.copyright,
.regulation p,
p.disclaimer {
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left;
}
p.footnote:lang(ar),
p.copyright:lang(ar),
.regulation p:lang(ar),
p.disclaimer:lang(ar) {
  font-size: 12px;
  margin-bottom: 10px;
  text-align: right;
}
ul.link-list {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* max-width: 230px; */
  margin-right: 0;
  margin-left: auto;
}

ul.link-list li a {
  font-size: 14px;
}

ul.link-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  /* margin: auto; */
}

ul.link-list li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  /* padding-bottom: 3px; */
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  color: #8b8b8b;
  text-align: left;
}

ul.link-list li a p {
  /* margin-left: 9px; */
  margin-bottom: 0;
  font-size: 13px;
  /* text-transform: uppercase; */
  width: 100%;
  font-weight: 500;
  text-align: left;
}

ul.link-list li a span img {
  width: 15px;
}

p.footnote {
  margin-bottom: 30px;
}

ul.link-list li a:hover {
  color: #111;
}

ul.link-list li a:hover span img {
  -webkit-filter: brightness(0);
  filter: brightness(0);
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

a.sitemap-link.socialLink .socialIcons {
  width: 20px;
  height: 20px;
  -webkit-filter: brightness(0.7);
  filter: brightness(0.7);
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

a.sitemap-link.socialLink {
  border-radius: 5px;
  /* background: rgb(183, 183, 183); */
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  width: 31px;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  -webkit-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  margin-right: 5px;
}

a.sitemap-link.socialLink:hover {
  background: #111111;
  -webkit-box-shadow: rgba(0, 0, 4, 0.57) 0px 0px 3px 0px;
  box-shadow: rgba(0, 0, 4, 0.57) 0px 0px 3px 0px;
}
a.sitemap-link.socialLink:hover img {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}
#footer {
  padding-top: 30px;
  padding-bottom: 60px;
  border-top: 1px solid rgb(0 0 0 / 6%);
}

#footer .link-list.social-iconz {
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
  /* padding-left: 15px; */
}
#footer .footer-borders {
  border-bottom: 1px solid #dee2e6 !important;
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
}
#footer .second-row ul {
  display: block;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
#footer .copyright {
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 10px;
}
#footer p.sitemap-titles {
  font-weight: 700;
  color: #111;
  margin-top: 10px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#footer .footer-logo img {
  max-width: 180px;
  width: 100%;
  -webkit-filter: brightness(0);
  filter: brightness(0);
  opacity: 0.7;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#footer .footer-logo img:hover {
  -webkit-filter: brightness(1);
  filter: brightness(1);
  opacity: 1;
}

@media screen and (max-width: 575px) {
  .footer-logo {
    text-align: center;
    margin-bottom: 20px;
  }
  #footer .link-list.social-iconz {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}
