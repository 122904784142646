#PaymentStructure table tr td {
  border: 1px solid #111111;
  text-align: center;
  padding: 10px;
}

#PaymentStructure table {
  width: 100%;
}
#PaymentStructure h1 {
  font-size: 30px;
  text-align: center;
  font-family: cursive;
}
#PaymentStructure .titleP {
  padding-top: 40px;
}
#PaymentStructure .titleP2 {
  padding-bottom: 20px;
}

#PaymentStructure .tablePay {
  border: 1px solid #111111;
  padding: 10px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

#PaymentStructure .payTables {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 575px) {
  #PaymentStructure table tr td {
    padding: 0;
  }
}
