#IndexingTerms {
  position: relative;
  min-width: 400px;
}
.indexingT {
  position: fixed;
  height: 100%;
  max-width: 400px;
  background-color: rgb(50, 54, 57);
  color: #ffffff;
}
#IndexingTerms ul {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  list-style-type: alpha-numeric;
}
#IndexingTerms li {
  cursor: pointer;
  transition: 0.3s all ease;
}
#IndexingTerms li::marker {
  color: #03a9f4 !important;
}
#IndexingTerms li:hover {
  color: #03a9f4;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s all ease;
}
.active,
.active #IndexingTerms li {
  color: #03a9f4 !important;
  text-decoration: none !important;
  transition: 0.3s all ease;
}
