#tradinginfo {
  padding-top: 100px;
  padding-bottom: 100px;
}
#tradinginfo .hflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.carouselrow {
  overflow: hidden;
  /* max-width: 1400px; */
}
.carouselcustom {
  display: flex;
  justify-content: space-between;
  animation: 90s ease-in 1s infinite reverse both running slide-left;
}

.carouselcustom:hover {
  animation-play-state: paused;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(-3200px);
    transform: translateX(-3200px);
  }
  100% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(-3200px);
    transform: translateX(-3200px);
  }
  100% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }
}

/* box-shadow: 0 0 1px 3px rgb(18 89 255); */
.carousel.carousel-slider [type="button"]:not(:disabled),
.carousel.carousel-slider [type="reset"]:not(:disabled),
.carousel.carousel-slider [type="submit"]:not(:disabled),
.carousel.carousel-slider button:not(:disabled) {
  cursor: pointer;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 100px;
}

@media (max-width: 991px) {
  #tradinginfo {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
