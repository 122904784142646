.page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
}
.page-controls span {
  font: inherit;
  font-size: 0.8em;
  padding: 0 0.5em;
}
.page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}
.page-controls button:enabled:hover {
  cursor: pointer;
}
.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
  background-color: #e6e6e6;
}
.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pdfContainer {
  width: 100%;
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
}
.pdfContainer > div {
  border: 1px solid #e3e3e3;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.react-pdf__Page__svg {
  width: auto !important;
  height: auto !important;
  border-radius: 8px;
}
@media (max-width: 600px) {
  .react-pdf__Page__svg svg {
    border-radius: 8px;
    max-width: 400px;
    width: 100vw;
    height: auto;
    display: block;
    background-color: white;
  }
}
