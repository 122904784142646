/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

#tradinginfo .tradingbox {
  width: 305px;
  min-width: 300px;
  height: 365px;
  border-radius: 10px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 30px;
  margin: 20px;
}
#tradinginfo .tradingbox h3 {
  text-align: left;
  font: normal normal bold 20px/27px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 15px;
  width: 100%;
}
#tradinginfo .tradingbox p {
  text-align: left;
  font: normal normal normal 14px/19px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
#tradinginfo .tradingbox img {
  height: 171px;
}

@media only screen and (max-width: 991px) {
  #tradinginfo .tradingbox {
    margin: auto;
  }
  .carousel-root {
    overflow: hidden;
  }
}
