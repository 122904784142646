.testimonialCard {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  min-height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.testimonialCard h5 {
  text-align: center;
  font: normal normal bold 24px/25px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.testimonialCard p {
  text-align: center;
  font: normal normal 400 18px/25px Poppins;
  letter-spacing: 0px;
  color: #777777;
  margin: 10px;
}
.cardTesti {
  min-height: 300px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.carousel.carousel-slider .control-arrow {
  margin-top: unset;
  margin: auto 14px;
}
.cardContent {
  max-width: 700px;
}
.testimonialCard img {
  width: 235px !important;
  height: 50px;
}

@media (max-width: 575px) {
  .testimonialCard h5 {
    font: normal normal bold 20px/22px Poppins;
  }
  .testimonialCard p {
    font: normal normal 400 14px/20px Poppins;
  }
  .testimonialCard {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 5px;
    min-height: 400px;
  }
}
