#perks {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f8f8f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.perk p {
  font-size: 20px;
  max-width: 206px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  margin: auto;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 0;
}

@media (max-width: 991px) {
  .perk p {
    font-size: 18px;
  }
}
