#thankYouMessage {
  position: fixed;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1900;
  background: rgba(1, 1, 1, 0.9);
  color: white;
}
span.emailSpan {
  color: #d36606 !important;
  font-weight: 600;
}
.boxSubmitted {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 80%;
  height: 80%;
  border-radius: 7px;
  padding: 30px;
  background: #101010;
  -webkit-box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #161616;
  box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #161616;
  justify-content: center;
  align-items: center;
  max-width: 430px;
  max-height: 580px;
}
.thank-you-container .button-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.thank-you-container .button-container.disable {
  opacity: 0;
  pointer-events: none;
}

.thank-you-container .button-container .close-thank-you {
  background: rgb(255 255 255 / 8%);
  color: #fff;
  border-radius: 7px;
  width: 200px;
  border: 1px solid rgb(255 255 255 / 21%);
  padding: 10px 10px;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  font-weight: 600;
  -webkit-box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #161616;
  box-shadow: 8px 8px 16px #0a0a0a, -8px -8px 16px #161616;
  outline: none;
  height: unset;
}
.thank-you-container .button-container .close-thank-you:hover {
  background: rgb(255 255 255 / 81%);
  color: #101010;
}
.iconBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
}

.textRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.textRow .larger {
  font-size: 16px;
  /* display: none; */
}

.textRow .smaller {
  font-size: 14px;
}
.userChecks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}

.userCheck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  margin: auto;

  justify-content: center;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.iconCheck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.iconCheck img {
  width: 60px;
  height: 60px;
  -webkit-filter: contrast(0) brightness(5);
  filter: contrast(0) brightness(5);
}
.textCheck {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 280px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 20px;
  font-size: 14px;
}

.textCheck p:nth-child(1) {
  font-size: 15px;
  font-weight: 700;
}

.textCheck p {
  margin-bottom: 0;
  text-align: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.textCheck p a {
  color: #046eff;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
a.openNewAccount {
  width: 170px;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #d36606;
  font-weight: 600;
  border-bottom: 1px solid #c66007;
  transition: all 0.15s linear;
  max-width: 100%;
}

a.openNewAccount:hover {
  color: rgb(211 102 6 / 69%);
  border-bottom: 1px solid rgb(198 96 7 / 0%);
}

@media (max-width: 575px) {
  .thank-you-container {
    width: 100%;
    height: 100%;
  }
  .textCheck {
    max-width: 170px;
  }
}
