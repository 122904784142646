#testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
}

#testimonials h1 {
  text-align: center;
  font: normal normal bold 50px/60px Poppins;
  letter-spacing: -0.93px;
  color: #000000;
}
#testimonials .contentT {
  text-align: center;
  font: normal normal normal 20px/24px Helvetica Neue;
  letter-spacing: 0px;
  color: #777777;
}
.carousel-root {
  overflow: hidden;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid rgb(18, 89, 255);
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid rgb(18, 89, 255);
}
@media (max-width: 575px) {
  #testimonials h1 {
    font: normal normal bold 30px/26px Poppins;
  }
  #testimonials {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
